import React from 'react';
// import sections
import Bookpage from '../components/sections/Bookpage';
// import FeaturesTiles from '../components/sections/FeaturesTiles';
// // import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
// // import Cta from '../components/sections/Cta';

const Booking = () => {

  return (
    <>
      <Bookpage/>
    </>
  );
}

export default Booking;